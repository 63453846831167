@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: local(Roboto Thin ), local(Roboto-Thin), url("roboto-latin-100.401f3749.woff2") format("woff2"), url("roboto-latin-100.28789877.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src: local(Roboto Thin italic), local(Roboto-Thinitalic), url("roboto-latin-100italic.a1931ae4.woff2") format("woff2"), url("roboto-latin-100italic.f980b363.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local(Roboto Light ), local(Roboto-Light), url("roboto-latin-300.81a5bc04.woff2") format("woff2"), url("roboto-latin-300.9527f5b1.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: local(Roboto Light italic), local(Roboto-Lightitalic), url("roboto-latin-300italic.4ad36a98.woff2") format("woff2"), url("roboto-latin-300italic.470fc714.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local(Roboto Regular ), local(Roboto-Regular), url("roboto-latin-400.962f1284.woff2") format("woff2"), url("roboto-latin-400.541b858a.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: local(Roboto Regular italic), local(Roboto-Regularitalic), url("roboto-latin-400italic.726359d1.woff2") format("woff2"), url("roboto-latin-400italic.876e597b.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local(Roboto Medium ), local(Roboto-Medium), url("roboto-latin-500.b3c683d1.woff2") format("woff2"), url("roboto-latin-500.5f8bfa54.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: local(Roboto Medium italic), local(Roboto-Mediumitalic), url("roboto-latin-500italic.21775980.woff2") format("woff2"), url("roboto-latin-500italic.605cdec2.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local(Roboto Bold ), local(Roboto-Bold), url("roboto-latin-700.41e719aa.woff2") format("woff2"), url("roboto-latin-700.f439c182.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: local(Roboto Bold italic), local(Roboto-Bolditalic), url("roboto-latin-700italic.00f6a7b9.woff2") format("woff2"), url("roboto-latin-700italic.55756cd7.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: local(Roboto Black ), local(Roboto-Black), url("roboto-latin-900.0f04a344.woff2") format("woff2"), url("roboto-latin-900.c4cd6965.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src: local(Roboto Black italic), local(Roboto-Blackitalic), url("roboto-latin-900italic.39f8a7c1.woff2") format("woff2"), url("roboto-latin-900italic.fa7a4a2e.woff") format("woff");
}

/*# sourceMappingURL=index.21a53bc1.css.map */
